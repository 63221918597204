<template>
  <div>
    <div>
      <van-cell-group>
        <van-field
          v-model="ActLogsFrom.Title"
          required
          label="标题"
          placeholder="请输入活动标题"
        />
        <!-- <van-field
          v-model="ActLogsFrom.Theme"
          label="主题"
          placeholder="请输入心得体会主题"
        /> -->
        <van-field
          v-model="ActLogsFrom.Content"
          rows="2"
          autosize
          label="内容"
          type="textarea"
          placeholder="请输入内容"
        />
      </van-cell-group>
      <van-cell-group>
        <van-field
          v-model="ActLogsFrom.StartTime"
          label="开始日期"
          @click="datafromStartDate = true"
          readonly="readonly"
          required
          placeholder="请选择开始参加活动时间"
        />
        <van-popup
          v-model="datafromStartDate"
          position="bottom"
          get-container="body"
        >
          <van-datetime-picker
            show-toolbar
            type="date"
            title="请选择开始日期"
            :min-date="minDate"
            :max-date="maxDate"
            @cancel="datafromStartDate = false"
            @confirm="datafromStartDateClick"
          >
          </van-datetime-picker>
        </van-popup>
      </van-cell-group>
      <van-cell-group>
        <van-field
          v-model="ActLogsFrom.EndTime"
          label="结束日期"
          @click="datafromEndDate = true"
          readonly="readonly"
          required
          placeholder="请选择结束参加活动时间"
        />
        <van-popup
          v-model="datafromEndDate"
          position="bottom"
          get-container="body"
        >
          <van-datetime-picker
            show-toolbar
            type="date"
            title="请选择结束日期"
            :min-date="minDate"
            :max-date="maxDate"
            @cancel="datafromEndDate = false"
            @confirm="ondatafromEndDate"
          >
          </van-datetime-picker>
        </van-popup>
      </van-cell-group>
      <div style="margin: 10px">
        <span style="margin-right: 20%; color: #646566; font-size: 14px"
          >图片</span
        >
        <van-uploader
          multiple
          class="imgs"
          :after-read="afterRead"
          :before-delete="jkDelete"
          v-model="ImageList"
          accept=""
          :max-count="1"
        >
        </van-uploader>
      </div>
      <div style="margin: 10px">
        <van-uploader
          max-count="1"
          accept="video/*"
          :after-read="handleAvatarSucces"
        >
          <van-button icon="video-o" plain type="info">上传视频</van-button>
        </van-uploader>
        <div
          class="video"
          style="width: 76%; margin: auto; margin-bottom: 75px"
          v-if="ActLogsFrom.Video"
        >
          <video
            style="width: 100%"
            :src="ActLogsFrom.Video"
            controls="controls"
          >
            您的浏览器不支持 video 标签。
          </video>
        </div>
        <!-- <div v-if="ActLogsFrom.Video" class="video">
          <video
            style="width: 100%"
            :src="ActLogsFrom.Video"
            controls="controls"
          >
            您的浏览器不支持 video 标签。
          </video>
        </div> -->
      </div>
      <!-- <div style="margin: 10px">
        <span style="margin-right: 20%; color: #646566; font-size: 14px"
          >视频</span
        >
        <van-uploader
          :after-read="upload"
          :before-delete="fjDelete"
          v-model="fileList"
          accept=""
          :max-count="1"
        >
        </van-uploader>
        <van-button icon="plus" plain style="border-radius: 7px" type="info"
          >上传附件</van-button
        >
      </div> -->
    </div>
    <div>
      <div style="width: 95%; bottom: 0; position: fixed; padding: 10px">
        <van-button
          type="info"
          size="normal"
          style="
            text-center: right;
            font-size: 16px;
            width: 100%;
            border-radius: 7px;
          "
          @click="saveRecActLogs()"
          color="#617bfa"
          >提交</van-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import Compressor from "compressorjs";
import { WxSaveActLogs, UploadQiniuUpToken } from "@/api/RealInfo";
import { setOpenId, getOpenId } from "@/utils/auth";
import config from "@/config";
import Vue from "vue";
import { Toast } from "vant";
import { Dialog } from "vant";
import axios from "axios";
Vue.use(Toast);
export default {
  data() {
    return {
      datafromStartDate: false,
      datafromEndDate: false,
      qiniutokent: {}, //上传视频传参
      ImageList: [],
      minDate: new Date(2020, 0),
      maxDate: new Date(2033, 0),
      list: {}, //活动详情
      ActLogsFrom: {
        openID: getOpenId(),
        AId: "",
        Title: "",
        Video: "",
        Content: "",
        Imgs: "",
        EndTime: "",
        StartTime: "",
      }, //活动申请提交参数
      fileList: [],
    };
  },
  created() {
    this.qiniutoken();
    // 获取并保存openid
    if (this.$route.query["openid"]) {
      setOpenId(this.$route.query["openid"]);
    }
  },
  methods: {
    // 结束日期
    ondatafromEndDate(val) {
      this.ActLogsFrom.EndTime = this.dateformat(val);
      this.datafromEndDate = false;
    },
    // 开始日期
    datafromStartDateClick(val) {
      this.ActLogsFrom.StartTime = this.dateformat(val);
      this.datafromStartDate = false;
    },
    dateformat(val) {
      // 时间格式化事件 yy-mm-dd
      let year = val.getFullYear();
      let month = val.getMonth() + 1;
      let day = val.getDate();
      if (month >= 1 && month <= 9) {
        month = `0${month}`;
      }
      if (day >= 1 && day <= 9) {
        day = `0${day}`;
      }
      return `${year}-${month}-${day}`;
    },
    // 获取七牛云token
    qiniutoken() {
      UploadQiniuUpToken().then((res) => {
        this.qiniutokent.token = res.data.token;
      });
    },
    // 上传 相关
    handleAvatarSucces(file) {
      Toast.loading({
        message: "上传中...",
        forbidClick: true,
        loadingType: "spinner",
      });
      let url = "http://upload-z2.qiniup.com";
      let formData = new FormData();
      formData.append("token", this.qiniutokent.token);
      formData.append("file", file.file); //必须是file.file，不然会报错
      //添加请求头
      let config = {
        headers: { "Content-Type": "multipart/form-data" },
      };
      axios.post(url, formData, config).then((res) => {
        this.ActLogsFrom.Video = "http://videolib.dexian.ren/" + res.data.key;
      });
    },
    // 上传附件
    upload: function (file) {
      var that = this;
      console.log(file.file);
      file.status = "uploading";
      file.message = "上传中...";
      var formData = new FormData();
      formData.append("file", file.file); // 文件对象
      let c = {
        headers: { "Content-Type": "multipart/form-data" },
      };
      axios.post(config.apiUploadFiles, formData, c).then((res) => {
        if (res.data.code === 0) {
          console.log(res.data.data);
          that.ActLogsFrom.Annex = res.data.data;
          file.status = "";
          file.message = "";
        } else {
          Toast.fail(res.data.msg);
        }
      });
    },
    //上传照片
    afterRead(file) {
      var that = this;
      file.status = "uploading";
      file.message = "上传中...";
      new Compressor(file.file, {
        quality: 0.5,
        success(result) {
          var formData = new FormData();
          let c = {
            headers: { "Content-Type": "multipart/form-data" },
          };
          //构造一个 FormData，把后台需要发送的参数添加
          formData.append("file", result, result.name);
          axios.post(config.apiUploadUrl, formData, c).then((res) => {
            if (res.data.code === 0) {
              console.log(res.data.data);
              that.ActLogsFrom.Imgs = res.data.picurl[0];
              // that.ActLogsFrom.EvalImgs.push(res.data.data);
              file.status = "";
              file.message = "";
            } else {
              Toast.fail(res.data.msg);
            }
          });
        },
      });
    },
    //删除照片
    jkDelete(file) {
      this.ImageList = [];
      this.ActLogsFrom.Imgs = "";
    },
    // 删除附件
    fjDelete: function () {
      this.fileList = [];
      this.ActLogsFrom.Annex = "";
    },
    saveRecActLogs: function () {
      if (this.ActLogsFrom.Title == "") {
        Toast.fail("请输入活动标题");
        return false;
      }
      this.ActLogsFrom.AId = this.$route.params.Id;
      console.log(this.ActLogsFrom, "this.ActLogsFrom");
      // return;
      WxSaveActLogs(this.ActLogsFrom).then((res) => {
        if (res.data.code == 0) {
          Dialog.alert({
            message: "提交成功!",
          }).then(() => {
            this.$router.go(-1);
          });
        } else {
          Dialog.alert({
            message: "提交失败," + res.data.msg,
          }).then(() => {});
        }
      });
    },
  },
};
</script>

<style scoped>
.buttons {
  text-align: center;
}
.buttons button {
  margin: 25px 10px;
  border-radius: 10px;
}
/deep/ .van-uploader {
  vertical-align: middle;
}
</style>